import { Editor } from '@tinymce/tinymce-react';
import React from 'react';

const EditorPertanyaan = ({ content, onContentChange }) => {
    return (
        <Editor
            apiKey='4wdora3p8dovdm5h60pdqd2bqdn855i1cxu4n8d6yg4ueftu'
            tinymceScriptSrc='https://cdn.tiny.cloud/1/4wdora3p8dovdm5h60pdqd2bqdn855i1cxu4n8d6yg4ueftu/tinymce/5/tinymce.min.js'
            value={content}
            onEditorChange={onContentChange}
            init={{
                external_plugins: {
                    tiny_mce_wiris:
                        '/plugins/wiris/mathtype-tinymce5/plugin.min.js'
                },
                menubar: false,
                paste_as_text: true,
                plugins: 'paste',
                forced_root_block: 'p',
                paste_data_images: false,
                resize: false,
                width: '100%',
                branding: false,
                toolbar:
                    'undo redo | bold italic |tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
                height: 200,
                setup: function (editor) {
                    editor.on('init', function () {
                        console.log('Editor is initialized');
                    });
                    editor.on('change', function (e) {
                        console.log('Content was changed:', e);
                    });
                }
            }}
        />
    );
};

export default EditorPertanyaan;
