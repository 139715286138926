import { Editor } from '@tinymce/tinymce-react';
import { Grid } from '@material-ui/core';
import {
    SelectValidator,
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import React from 'react';
const EditorJawaban = ({
    content,
    onContentChange,
    onChangeJawaban,
    value
}) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={6} sm={6} style={{ height: '300px' }}>
                <Editor
                    apiKey='4wdora3p8dovdm5h60pdqd2bqdn855i1cxu4n8d6yg4ueftu'
                    tinymceScriptSrc='https://cdn.tiny.cloud/1/4wdora3p8dovdm5h60pdqd2bqdn855i1cxu4n8d6yg4ueftu/tinymce/5/tinymce.min.js'
                    value={content}
                    onEditorChange={onContentChange}
                    init={{
                        external_plugins: {
                            tiny_mce_wiris:
                                '/plugins/wiris/mathtype-tinymce5/plugin.min.js'
                        },
                        menubar: false,
                        plugins: 'paste',
                        forced_root_block: 'p',
                        paste_as_text: true,
                        paste_data_images: false,
                        resize: false,
                        width: '100%',
                        branding: false,
                        toolbar:
                            'undo redo | bold italic |tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
                        height: 200,
                        setup: function (editor) {
                            editor.on('init', function () {
                                console.log('Editor is initialized');
                            });
                            editor.on('change', function (e) {
                                console.log('Content was changed:', e);
                            });
                        }
                    }}
                />
            </Grid>

            <Grid item sm={6} xs={6}>
                <div style={{ width: '100%' }}>
                    <TextValidator
                        className='w-full'
                        label='Nilai Jawaban'
                        onChange={(event) => {
                            let value = parseFloat(event.target.value);
                            if (isNaN(value) || value < 0) {
                                value = 0;
                            }
                            onChangeJawaban(value);
                        }}
                        type='number'
                        name='value'
                        value={value}
                        validators={['required']}
                        errorMessages={['Field is Required']}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default EditorJawaban;
