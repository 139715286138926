import { Card, Icon, IconButton } from '@material-ui/core';
import React, { Fragment } from 'react';
import ReactPlayer from 'react-player';
import { MathJaxContext, MathJax } from 'better-react-mathjax';

import { ButtonSolid } from '..';

const CardSoalAdmin = ({
    title,
    subtitle,
    soal,
    jawaban = [],
    jml_soal,
    no_soal,
    handleChangeNo,
    soal_img,
    pembahasan,
    handleEditSoal,
    handleDeleteSoal,
    pembahasan_img,
    pembahasan_video,
    category,
    subcategory
}) => {
    let jawabanBenar;
    let highValue = Math.max.apply(
        Math,
        jawaban.map((item) => item.value)
    );
    let benar = jawaban.filter((item) => item.value == highValue);
    if (benar.length > 0) {
        jawabanBenar = benar[0].opt;
    }
    // let jawabanTKP = '';
    // let jwb = jawaban.sort((a, b) =>
    //     parseInt(a.value) < parseInt(b.value) ? 1 : -1
    // );
    // for (let i = 0; i < jwb.length; i++) {
    //     jawabanTKP += jwb[i].opt.toUpperCase();
    // }
    const capitalizeFirstLetter = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };
    const hapusTagP = (str) => {
        return str.replace(/^<p>/, '').replace(/<\/p>$/, '');
    };
    const isHTML = (str) => {
        const doc = new DOMParser().parseFromString(str, 'text/html');
        return Array.from(doc.body.childNodes).some(
            (node) => node.nodeType === 1
        );
    };
    return (
        <Card elevation={6} className='px-6 py-5 h-full card-soal'>
            <div className='flex justify-between items-center'>
                <h5 className='m-0'>
                    {title} {category && '|' + category.toUpperCase() + ' - '}
                    {subcategory && subcategory.replace('_', ' ').toUpperCase()}
                </h5>
                <div>
                    <IconButton onClick={handleEditSoal} title='Edit'>
                        <Icon color='primary'>edit</Icon>
                    </IconButton>
                    <IconButton onClick={handleDeleteSoal} title='delete'>
                        <Icon color='error'>delete</Icon>
                    </IconButton>
                </div>
            </div>
            {subtitle && <div className='card-subtitle mb-4'>{subtitle}</div>}
            <div className='bordered mb-5 mt-4'></div>
            {soal_img && (
                <div style={{ overflow: 'auto' }}>
                    <img src={soal_img} className='img-soal max-w-400' />
                </div>
            )}
            <MathJaxContext>
                <MathJax dynamic>
                    <div
                        className='soal mb-5'
                        dangerouslySetInnerHTML={{ __html: soal }}
                    ></div>
                </MathJax>
            </MathJaxContext>
            {jawaban &&
                jawaban.map((item, index) => (
                    <MathJaxContext>
                        <MathJax dynamic>
                            <div
                                key={index}
                                className={`MuiTypography-root MuiFormControlLabel-label MuiTypography-body1 flex
                            font-medium
                            h5
                            ${jawabanBenar == item.opt ? 'text-primary' : null}
                        `}
                            >
                                <p className='mt-0 mb-0 mr-2'>{`${item.opt.toUpperCase()}.`}</p>
                                <p
                                    className={
                                        isHTML(item.text)
                                            ? 'mt-0 mb-0'
                                            : 'mt-0 mb-0'
                                    }
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                item.text &&
                                                hapusTagP(item.text)
                                        }}
                                    ></span>
                                </p>
                                {item.img && (
                                    <img
                                        src={item.img}
                                        alt='..'
                                        style={{ maxWidth: '100px' }}
                                    />
                                )}
                            </div>
                        </MathJax>
                    </MathJaxContext>
                ))}
            <div className='border-bottom-1 mt-4 mb-3'></div>

            <Card className='my-4 border-radius-4 px-8 py-4' elevation={3}>
                {category != 'tkp' && (
                    <p className='m-0 h5 font-medium mb-3'>
                        Kunci Jawaban:{' '}
                        <span className='text-primary'>
                            {jawabanBenar?.toUpperCase()}
                        </span>
                    </p>
                )}
                <h5 className='py-4 border-top-1 border-bottom-1'>
                    Pembahasan
                </h5>
                <MathJaxContext>
                    <MathJax dynamic>
                        <div
                            className='soal'
                            dangerouslySetInnerHTML={{ __html: pembahasan }}
                        ></div>
                    </MathJax>
                </MathJaxContext>
                {pembahasan_video && (
                    <ReactPlayer
                        url={pembahasan_video}
                        className='react-player'
                        width='100%'
                        style={{ overflow: 'hidden' }}
                        config={{
                            youtube: { playerVars: { disablekb: 1 } }
                        }}
                        controls
                    />
                )}
                {pembahasan_img && (
                    <img
                        src={pembahasan_img}
                        alt='...'
                        style={{ width: 'auto' }}
                    />
                )}
            </Card>

            <div className='flex items-center'>
                {no_soal != 0 ? (
                    <Fragment>
                        <ButtonSolid
                            variant='button-blue'
                            icon='chevron_left'
                            text='Sebelumnya'
                            onClick={() => handleChangeNo(no_soal - 1)}
                        />
                        <div className='mr-5'></div>
                    </Fragment>
                ) : null}
                {no_soal + 1 < jml_soal ? (
                    <Fragment>
                        <ButtonSolid
                            variant='button-purple'
                            iconRight='chevron_right'
                            text='Selanjutnya'
                            onClick={() => handleChangeNo(no_soal + 1)}
                        />
                    </Fragment>
                ) : null}
            </div>
        </Card>
    );
};

export default CardSoalAdmin;
